<template>
  <v-navigation-drawer
    id="app-product-drawer"
    v-model="inputValue"
    right
    app
    data-cy="product-drawer"
    :width="productDrawerWidth"
  >
    <v-list
      dense
      nav
      class="py-0"
    >
      <v-subheader v-t="'productDetails.availableContracts'" />
      <v-list-item
        v-for="contract in productContracts"
        :key="contract.id"
        :data-cy="contract.id"
        class="py-2 mt-1"
        :to="{
          name: productDetailsRoute,
          params: {
            ...$route.params,
            contractId: contract.id,
          },
        }"
      >
        <v-list-item-title>
          <div>
            <div>
              <span v-t="'contractCard.contractId'" />
              <br>
              <b>{{ contract.id }}</b>
            </div>
            <div>
              <b class="mr-1">{{ contract.product.name }}</b>
              <!-- eslint-disable vue/no-v-html -->
              <span
                class="mr-1"
                v-html="getLocalizedContract(contract)"
              />
            </div>
            <div v-if="contract.product.type === typeCloud">
              {{ contract.product.domain }}
            </div>
            <div v-else>
              <span
                v-t="'productDetails.updatesUntil'"
                class="mr-2"
              />
              {{ contract.product.expiration | formatDate }}
            </div>
          </div>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import {
  TYPE_CLOUD,
  TYPE_SERVER,
  PRODUCT_DRAWER_WIDTH
} from '../../constants/app'
import { ROUTE_PRODUCT_DETAILS } from '../../constants/routeNames'
import getLocalizedContractProductType from '../../utils/getLocalizedContractProductType'

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      productDrawerWidth: PRODUCT_DRAWER_WIDTH,
      productDetailsRoute: ROUTE_PRODUCT_DETAILS,
      typeCloud: TYPE_CLOUD,
      typeServer: TYPE_SERVER
    }
  },
  computed: {
    ...mapGetters('contract', { productContractsGetter: 'productContracts' }),
    productContracts () {
      return this.productContractsGetter(this.$route.params.clientId)
    },
    inputValue: {
      get () {
        return this.$store.state.layout.productsDrawer
      },
      set (val) {
        this.setProductsDrawer(val)
      }
    }
  },
  methods: {
    ...mapMutations('layout', ['setProductsDrawer']),
    getLocalizedContract (contract) {
      return getLocalizedContractProductType(contract)
    },
    camelize (string) {
      return this.$options.filters.camelize(string)
    }
  }
}
</script>
